// eslint-disable-next-line import/prefer-default-export
export const initialState = {
  user: null,
  bookStatusNames: [],
  orders: [],
  choiceOrders: [],
  notShoosenReasons: [],
  contacts: {
    byId: {},
    allIds: [],
  },
}
