import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {MasterLayoutNew} from '../../_metronic/layout/MasterLayoutNew'
import {MainMasterLayout} from '../../_metronic/layout/MainMasterLayout'

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/order/ProfilePage'))
  //const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const ChoiceOrder = lazy(() => import('../modules/choice-order/ChoiceOrder'))
  const BuildYourBox = lazy(() => import('../modules/profile/BuildYourBox'))
  const BuildYourBox2 = lazy(() => import('../modules/profile/BuildYourBox2'))
  const ExcludedGenres = lazy(() => import('../modules/profile/ExcludedGenres'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MainMasterLayout />}>
        <Route element={<MasterLayoutNew />}>
          <Route
            path='order/*'
            element={
              <>
                <SuspensedView>
                  <ProfilePage />
                </SuspensedView>
              </>
            }
          />
        </Route>
      </Route>
      <Route element={<MainMasterLayout />}>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/order' />} />
          {/* Redirect to buildyourbox */}
          <Route path='auth/*' element={<Navigate to='/buildyourbox' />} />
          {/* Redirect to buildyourbox2 */}
          <Route path='auth/*' element={<Navigate to='/buildyourbox2' />} />
          {/* Redirect to Excluded Genres */}
          <Route path='auth/*' element={<Navigate to='/excludedgenres' />} />
          {/* Pages */}
          <Route path='dashboard' element={<ProfilePage />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          {/* Lazy Modules */}
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />

          <Route
            path='buildyourbox/*'
            element={
              <SuspensedView>
                <BuildYourBox />
              </SuspensedView>
            }
          />
          <Route
            path='ChoiceOrder/*'
            element={
              <SuspensedView>
                <ChoiceOrder />
              </SuspensedView>
            }
          />
          <Route
            path='buildyourbox2/*'
            element={
              <SuspensedView>
                <BuildYourBox2 />
              </SuspensedView>
            }
          />
          <Route
            path='excludedgenres/*'
            element={
              <SuspensedView>
                <ExcludedGenres />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
