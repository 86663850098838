/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import {toAbsoluteUrl} from '../../helpers'

const FooterNew: FC = () => {
  const {classes} = useLayout()
  return (
    <div id='kt_footer'>
      <footer className='site-footer'>
        <div className='container'>
          <div className='image-box'>
            <img src={toAbsoluteUrl('/media/Images/footer-img.png')} />
          </div>
        </div>
      </footer>
      <div className='copy-right-section'>
        <div className='container'>
          <p>© 2024, A Box of Stories</p>
        </div>
      </div>
    </div>
  )
}

export {FooterNew}
