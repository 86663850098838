import React, {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation} from 'react-router-dom'
import {
  DrawerMessenger,
  RightToolbar,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials'
/* import {MenuComponent} from '../../_metronic/assets/ts/components' */
import clsx from 'clsx'
import {toAbsoluteUrl} from '../helpers'
import {FooterNew} from './components/FooterNew'

const MasterLayoutNew: React.FC = ({children}) => {
  const {classes} = useLayout()

  const location = useLocation()
  useEffect(() => {
    /*  setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500) */
    document.body.style.backgroundImage = 'none'
  }, [])

  /* useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key]) */

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          {/* <HeaderWrapper /> */}

          {/*   <div className='announcement-bar top'>
            <div className='container'>
              <p>
                Offer: Subscribe & Save 20%. Guaranteed rewards in every 3rd box. From a Free Extra
                Book to Surprise Discounts. Claim Now!
              </p>
            </div>
          </div> */}
          <header>
            <div className='site-header'>
              <div className='container'>
                <div className='site-logo'>
                  <img src={toAbsoluteUrl('/media/Images/header-logo.png')} />
                  {/* <img src='Images/header-logo.png' /> */}
                </div>
                <h3>A digital home for your ABoS Books</h3>
              </div>
            </div>
          </header>

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start',
                classes.contentContainer.join(' ')
              )}
              id='kt_post'
            >
              <AsideDefault />
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <FooterNew />
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer />
      <RightToolbar />
      <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayoutNew}
