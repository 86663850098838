//import { OPEN_SIDEBAR, CLOSE_SIDEBAR } from 'src/actions/chatActions';
import {initialState} from './InitialState'

export const actions = {
  SET_USER: 'SET_USER',
  SET_ORDERS: 'SET_ORDERS',
  SET_Choice_ORDERS: 'SET_Choice_ORDERS',
  SET_Not_Choosen_Reasons: 'SET_Not_Choosen_Reasons',
  SET_BOOK_STATUS_NAME: 'SET_BOOK_STATUS_NAME',
  SET_OPTION_NAME: 'SET_OPTION_NAME',
  SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
  SET_LOADER: 'SET_LOADER',
}
export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      }
    case actions.SET_Choice_ORDERS:
      return {
        ...state,
        choiceOrders: action.payload,
      }
    case actions.SET_Not_Choosen_Reasons:
      return {
        ...state,
        notShoosenReasons: action.payload,
      }
    case actions.SET_BOOK_STATUS_NAME:
      return {
        ...state,
        bookStatusNames: action.payload,
      }
    case actions.SET_OPTION_NAME:
      return {
        ...state,
        optionNames: action.payload,
      }
    case actions.SET_USER:
      return {
        ...state,
        user: action.payload,
      }
    case actions.SET_CUSTOMER_DATA:
      return {
        ...state,
        customer_data: action.payload,
      }
    case actions.SET_LOADER:
      return {
        ...state,
        loader_check: action.payload,
      }
    default:
      return state
  }
}
