import {Alert, Box, Grid, Modal, Snackbar} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {saveCustomerFeedback} from '../../services/api/ApiManager'
import moment from 'moment'

const MainMasterLayout: React.FC = ({children}) => {
  const queryParams = new URLSearchParams(window.location.search)
  let email = queryParams.get('email')
  let customerId = queryParams.get('customerId')
  let store = queryParams.get('store')
  const [open, setOpen] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarVariant, setSnackbarVariant] = useState('success')
  const [comments, setComments] = useState('')
  const toggleOpen = () => {
    setOpen((open) => !open)
    setComments('')
  }
  const toggleOpenSnackbar = () => {
    setOpenSnackbar((open) => !open)
  }
  const postComment = async () => {
    const data = {
      shopify_customer_id: customerId,
      email: email,
      comments: comments,
      user: 'foxy-customer',
      timestamp: moment().format('YYYY-MM-DD hh-mm-ss'),
    }
    const res = await saveCustomerFeedback(data)
    if (res?.status === 200) {
      toggleOpenSnackbar()
      setSnackbarVariant('success')
      toggleOpen()
    } else {
      toggleOpenSnackbar()
      setSnackbarVariant('error')
    }
  }

  return (
    <>
      <div
        style={{
          position: 'fixed',
          right: '0',
          top: '50%',
          bottom: '50%',
          transform: 'rotate(90deg)',
          zIndex: 99999,
        }}
      >
        <span
          style={{backgroundColor: 'yellow', cursor: 'pointer'}}
          className='px-3 py-2 fw-bold fs-5'
          onClick={toggleOpen}
        >
          Feedback
        </span>
      </div>
      <Modal
        open={open}
        onClose={toggleOpen}
        aria-labelledby='child-modal-title'
        aria-describedby='child-modal-description'
      >
        <Box sx={{...style, width: {md: 600, sm: 400, xs: 320}}}>
          <label className='form-label'>Feedback</label>
          <textarea
            onChange={(e) => setComments(e?.target?.value || '')}
            className='w-100 p-2 form-control'
            rows={6}
            value={comments}
            maxLength={500}
            autoFocus
          />
          {/* <div className='d-flex justify-content-end'>
            <span>{comments.length}/500</span>
          </div> */}

          <div className='d-flex justify-content-between align-items-center mt-2'>
            <button
              onClick={toggleOpen}
              className='btn btn-sm btn-secondary btn-active-light-primary fw-bolder'
            >
              Cancel
            </button>
            <button onClick={postComment} className='btn btn-sm btn-primary fw-bolder'>
              Submit
            </button>
          </div>
        </Box>
      </Modal>
      {snackbarVariant === 'success' && (
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={toggleOpenSnackbar}>
          <Alert
            onClose={toggleOpenSnackbar}
            severity={'success'}
            variant='filled'
            sx={{width: '100%'}}
          >
            Feedback sent.
          </Alert>
        </Snackbar>
      )}
      {snackbarVariant === 'error' && (
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={toggleOpenSnackbar}>
          <Alert
            onClose={toggleOpenSnackbar}
            severity={'error'}
            variant='filled'
            sx={{width: '100%'}}
          >
            Something went wrong
          </Alert>
        </Snackbar>
      )}

      <Outlet />
    </>
  )
}

export {MainMasterLayout}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 320,
  bgcolor: 'background.paper',
  boxShadow: 0,
  px: 4,
  py: 2,
}
